$(() => {
  const $facetCollapse = $('.facet .collapse')

  if ($facetCollapse.length) {
    $facetCollapse.on('show.bs.collapse', function () {
      $(this).closest('.facet').find('.icon').addClass('icon--rotate')
    })

    $facetCollapse.on('hide.bs.collapse', function () {
      $(this).closest('.facet').find('.icon').removeClass('icon--rotate')
      $(this).closest('.facet').find('.facet__heading').blur()
    })
  }

  $('[data-collapse=full]').on('click', function() {
    const closestContainer = this.closest('.collapse__container'),
    $collapseContent = $('.collapse--full', closestContainer),
    $trigger = $(this),
    duration = $trigger.data('duration'),
    isOpen = !$collapseContent.hasClass('show')

    toggleTrigger($trigger, $collapseContent)
    toggleContent($collapseContent, duration)
    toggleContainerStatus($trigger, $collapseContent, isOpen)

    $trigger.blur()

  })

})

function toggleContent ($collapseContent, duration) {
  $collapseContent.toggleClass('collapsing')
  setTimeout(() => {
    $collapseContent.toggleClass('collapsing').toggleClass('show')
  }, duration)
}

function toggleCaret ($trigger) {
  $trigger.find('.icon__caret').toggleClass('show')
}

function toggleTimes ($trigger) {
  $trigger.find('.icon__times').toggleClass('show')
}

function moveText ($handler) {
  $handler.find('.btn__title').toggleClass('move-text')
}

function toggleContainerStatus($trigger, $collapseContent, isOpen) {
  const tabIndexValue = (isOpen) ? '0' : '-1'
  $collapseContent.find('a').attr('tabindex', tabIndexValue)
  $trigger.attr('aria-expanded', isOpen)
}

function toggleTrigger ($trigger) {
  toggleCaret($trigger)
  toggleTimes($trigger)
  moveText($trigger)
}