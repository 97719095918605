$(() => {
  $('.variants').find('input[type=radio]').change(function() {
    const combination = $(this).data('js'),
          url = '/api/combinations/' + combination,
          $tile = $(this).closest('.tile__product'),
          $image = $tile.find('.tile__product__image'),
          $price = $tile.find('.price'),
          price = $price.data('price'),
          token = 'RH4M7E367AKTRAUYQA8SLMC5S9Z4VIMV'
    let imageUrl = '',
        priceSubtrahend = 0,
        $result = null

    $.ajax({
      url,
      type: 'GET',
      data: {
        'ws_key': token
      },
      success: result => {
        $result = $(result)
        imageUrl = $result.find('image').attr('xlink:href') + '?ws_key=' + token
        priceSubtrahend = parseFloat($result.find('price').text()).toFixed(2),
        variantPrice = price - priceSubtrahend

        $image.attr('src', imageUrl)
        $price.text(variantPrice.toLocaleString('pl-PL', {style: 'currency', currency: prestashop.currency.iso_code}))

      },
      error: function (jqXHR, textStatus, errorThrown) {}
    })

  })
})