$(() => {
  $('body').on('click', '.quantity__decrease', e => {
    editQuantity('decrease', $(e.target))
  })

  $('body').on('click', '.quantity__increase', e => {
    editQuantity('increase', $(e.target))
  })
})

function editQuantity (editType, $handler) {
  const $quantityField = $handler.closest('.quantity__container').find('.js-cart-line-product-quantity'),
  quantityMax = parseInt($quantityField.attr('max')),
  productID = parseInt($quantityField.data('product-id'))
  let quantityValue = parseInt($quantityField.val())

  if (quantityValue > 0) {
    updateCart(productID, editType)
  }
}

function updateCart (productID, editType) {
  const token = prestashop.static_token,
  url = prestashop.urls.pages.cart,
  type = editType === 'decrease' ? 'down' : 'up',
  $alertHandler = $('#cartAlert')

  $alertHandler.hide()

  const query = 'controller=cart&add=1&op=' + type + '&action=update&ajax=true&token=' + token + '&id_product=' + productID + '&id_customization=0&qty=1'

  $.post(url, query, null, 'json').then((resp) => {
    if (resp.hasError) {
      (resp.quantity) ? $alertHandler.text(resp.errors[0]).show() : $alertHandler.show()
    } else {
      prestashop.emit('updateCart', {
        reason: {}, resp: resp
      })
    }
  }).fail((resp) => {
    prestashop.emit('handleError', {eventType: 'addProductToCart', resp: resp})
  })
}