$(() => {
  checkCookie()

  if ($('#cookies').length) {
    $('body').on('click', '#cookiesAccept', () => {
      removeCookiesInfo()
      setCookie("acceptTerm", true, 90)

    })
  }
})

function setCookie(cname, cvalue, exdays) {
  let d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  var expires = "expires=" + d.toUTCString()
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

function getCookie(cname) {
  const name = cname + "="
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
          c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
      }
  }
  return ""
}

function checkCookie() {
  const acceptTerm = getCookie("acceptTerm")
  if (acceptTerm) {
    removeCookiesInfo()
  } else {
    showCookiesInfo()
  }
}

function removeCookiesInfo() {
  $("#cookies").remove()
}

function showCookiesInfo() {
  $("#cookies").show()
}