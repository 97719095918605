import {DataService} from './storage.service'

$(() => {
  const $modal = $('#modal[data-show]'),
  $newsletterModal = $('#newsletterModal'),
  isFirstVisit = (DataService.getFirstVisit() === null)

  if ($modal.length) {
    showModal($modal)
  }

  toggleNewsletterModal(isFirstVisit, $newsletterModal)
  checkFirstVisit(isFirstVisit)

  onSearch()

})

const showModal = function ($modal, options = { show: true }) {
  $modal.modal(options)
}

const checkFirstVisit = (isFirstVisit) => {
  if (isFirstVisit) {
    DataService.saveFirstVisit(false)
  }
}

const toggleNewsletterModal = (isFirstVisit, $newsletterModal) => {
  if ($newsletterModal.length && isFirstVisit) {
    showModal($newsletterModal)
  }
}

const onSearch = () => {
  const $searchModal = $('#searchModal'),
  $searchBtn = $('.search__trigger')

  $searchBtn.on('click', () => {
    showModal($searchModal, {
      show: true,
      backdrop: false
    })
  })
}

export {showModal}