$(() => {
  initEvents()
})

function toggleDropdown (e) {
  const $dropdown = $(e.target).closest('.dropdown'),
    $openedElements = $('.submenu', $dropdown),
    $dropdownLink = $dropdown.find('.nav-link')
  const shouldOpen = e.type !== 'click' && ($dropdown.is(':hover') || $dropdownLink.is(':focus'))

    $openedElements.toggleClass('show', shouldOpen)
    $dropdownLink.attr('aria-expanded', shouldOpen)
}

function hideDropdown (e) {
  $('.submenu').toggleClass('show', false)
}

function toggleCollapse (e) {
  const submenuId = $(e.target).data('target')
  if (submenuId) {
    e.preventDefault()
    $(submenuId).collapse('toggle')
  }
}

function initEvents () {
  const desktopMedia = window.matchMedia( "(min-width: 992px)" )
  if (desktopMedia.matches) {
    $('body').on('mouseenter mouseleave focus', '.dropdown:not(.language-selector)', toggleDropdown)
    $('.navbar').on('focus', 'a:not(.dropdown, .submenu-link)', hideDropdown)
  } else {
    $('.dropdown .nav-link').click(toggleCollapse)

    $('body').on('click', '#quickStartGuideBtn', () => {
      $('body').toggleClass('position-fixed')
    })
  }

  $('body').on('click', '#navbarToggler', () => {
    $('#navbarNav').toggleClass('show')
    $('.navbar__right__icons').toggleClass('show')
    $('body').toggleClass('position-fixed')
  })

  const desktopMenu = window.matchMedia( "(min-width: 1200px)" )
  if (desktopMenu.matches) {
    $('body').on('mouseenter mouseleave focus', '#languageSelector', toggleDropdown)
  }
}