export default class Select {
  constructor ($handler, options) {
    this.$handler = $handler
    this.options = options
  }

  init () {
    this.$handler.select2(this.options)

    $('b[role="presentation"]').hide()
    $('.select2-selection__arrow').append('<span class="icon icon__caret"></span>')
  }

  reInit () {
    this.$handler.select2("destroy")
    this.init()
  }
}