$(() => {
  let $form = $('form')

  $form.on('submit', function() {
    $form = $(this).closest('form')
    validate($form)
  })

})

function validate ($form) {
  if ($form[0].checkValidity() === false) {
    event.preventDefault()
    event.stopPropagation()
  }
  $form.addClass('was-validated')
  checkPasswordField()
}

function checkPasswordField () {
  const $password = $('.form-password')
  if ($password.length) {
    const isInvalid = $password.find('input[type=password]:invalid').length
    const $fieldComment = $password.next('.form-control-comment')

    if (isInvalid) {
      $fieldComment.addClass('text--red')
    } else {
      $fieldComment.removeClass('text--red')
    }
  }
}