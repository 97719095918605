const FIRST_VISIT = 'first_visit'

const DataService = {
  getFirstVisit () {
    return localStorage.getItem(FIRST_VISIT)
  },

  saveFirstVisit (value) {
    localStorage.setItem(FIRST_VISIT, value)
  },

  removeFirstVisit () {
    localStorage.removeItem(FIRST_VISIT)
  },
}

export { DataService }
