$(() => {
  let currentNumber = 1
  const $carouselNumber = $('#carouselNumber'),
    $carousel = $('#carousel')

  $carousel.on('slid.bs.carousel', () => {
    currentNumber = $('.carousel-item.active').index() + 1
    currentNumber = ('0' + currentNumber).slice(-2)
    $carouselNumber.text(currentNumber)
  })
})