import 'select2/dist/css/select2.min.css'
import '../style/theme.scss'

import 'select2/dist/js/select2.min'
import 'bootstrap'

import './navbar'
import './collapse'
import './variants'
import './slider'
import './cart'
import './validation'
import './accordion'
import './cookies'

import Select from './select'
import { showModal } from './modal'
import Map from './map'

import refreshSliders from './libraries/ps_facetedsearch/slider'

$(() => {
  initSortSelect()

  $('body').on('change', '#search_filters input[data-search-url]', (t) => {
    prestashop.emit('updateFacets', t.target.dataset.searchUrl)
  })

  $('body').on('click', '.js-search-filters-clear-all', (t) => {
    prestashop.emit('updateFacets', t.target.dataset.searchUrl)
  })

  $('body').on('change', '.js-search-link', function() {
    prestashop.emit('updateFacets', $(this).val())
  })

  $('body').on('click', '.js-pagination', function(e) {
    e.preventDefault()
    prestashop.emit('updateFacets', $(this).attr('href'))
  })

  prestashop.on('updateProductList', data => {
    refreshView(data),
    window.scrollTo(0, 0)
  })

  prestashop.on('updateCart', data => {
    const $addToCart = $('[data-button-action="add-to-cart"]')
    if ($addToCart.length) {
      setTimeout (() => {
        $addToCart.attr('disabled', false)
        $addToCart.find('.text__loading').hide()
        $addToCart.find('.text__normal').show()
      }, 500)
    }
  })

  $('body').on('click', '[data-button-action="add-to-cart"]', function () {
    const $this = $(this)
    $this.attr('disabled', true)
    $this.find('.text__normal').hide()
    $this.find('.text__loading').show()
  })

  $('body').on('click', '#searchFilterToggler', e => {
    $('#leftColumn').addClass('d-block search-filters--absolute')
  })

  $('body').on('click', '#searchFilterTogglerCollapse', e => {
    $('#leftColumn').removeClass('d-block search-filters--absolute')
  })

  prestashop.on('updateCart', () => {
    setTimeout(()=>{showModal($('#blockcart-modal'))},500)
  })

  prestashop.on('updatedProduct', (data) => {
    toggleProductImageView ()
    refreshProductView(data)
    $('.tooltip').remove()
    $('[data-toggle="tooltip"]').tooltip()
  })

  $('body').on('click', '.btn[data-action=show-password]', function() {
    const $parentContainer  = $(this).closest('.js-parent-focus'),
    $field = $parentContainer.find('.js-child-focus'),
    fieldType = $field.attr('type'),
    fieldNewType = (fieldType === 'password') ? 'text' : 'password'

    $field.attr('type', fieldNewType)
  })

  $('[data-toggle="tooltip"]').tooltip()

  $('body').on('change', '.js-payment-option', function() {
    const $parentContainer = $(this).closest('.payment__option__container'),
    labelClass = '.payment__option'

    $(labelClass).removeClass('active')
    $parentContainer.find(labelClass).addClass('active')
  })

  $('body').on('change', '.js-delivery-option', triggerDeliveryShops)

  const $map = document.getElementById('map')
  const $markerButtons = document.querySelectorAll('[data-pan-to-marker]')
  if ($map) {
    const mapInstance = new Map($map, {lat: 52.4075926, lng: 16.9224806}, 12)
    mapInstance.init()

    if ($markerButtons && $markerButtons.length) {
      $markerButtons.forEach($markerButton => {
        $markerButton.addEventListener('click', marker => {
          mapInstance.panToMarker(JSON.parse(marker.target.dataset.coords))
        })
      })
    }
  }

  $('body').on('mouseover', '.variant__color__label', function() {
    const url = $(this).find('.variant__color__image').attr('src')

    if ($('#productHover').is(':hidden')) {
      $('#productCarouselIndicators').hide()
      $('#productHover').show()
      $('#productImageHover').attr('src', url)
    }
  })

  $('body').on('mouseout', '.variant__color__label', () => {
    toggleProductImageView ()
  })

  $('body').on('click', '.variant__color__label', () => {
    toggleProductImageView ()
  })

  $('body').on('click', '#blockcart-modal [data-dismiss="modal"]', () => {
    $.ajax({
      data: {
        ajax: 1,
        action: "refresh"
      }
    }).done((data) => {
      refreshProductView(data)
    })
  })

  const $productDetails = $('#product-details')
  if ($productDetails && prestashop) {
    prestashop.product = $productDetails.data('product')
  }

  $('body').on('click', 'a', function() {
    $(this).blur()
  })

  const $fileInput = $('#fileInput')
  if ($fileInput.length) {
    var button = document.querySelector( ".input-file-trigger" ),
    the_return = document.querySelector(".file-return")

    button.addEventListener( "keydown", ( event ) => {
        if ( event.keyCode == 13 || event.keyCode == 32 ) {
            fileInput.focus()
        }
    })
    button.addEventListener( "click", ( event ) => {
      fileInput.focus()
      return false
    })
    fileInput.addEventListener( "change", function( event ) {
        the_return.innerHTML = this.value.replace(/C:\\fakepath\\/i, '')
    })
  }

  const $deliveryOptions = $('.js-delivery-option')
  if ($deliveryOptions.length) {
    triggerDeliveryShops()
  }
})

function refreshView(data) {
  $("#search_filters").replaceWith(data.rendered_facets)
  $("#js-active-search-filters").replaceWith(data.rendered_active_filters)
  $("#js-product-list-top").replaceWith(data.rendered_products_top)
  $("#js-product-list").replaceWith(data.rendered_products)
  $("#js-product-list-bottom").replaceWith(data.rendered_products_bottom)
  $("#js-product-list-header").replaceWith(data.rendered_products_header)
  refreshSliders()
  initSortSelect()
}

function refreshProductView(data) {
  const referenceText = $(data.product_details).find('#referenceTextHidden').text()
  $("#referenceText").text(referenceText)

  // overwrite 1.7.6.3 bug
  $(".quickview .product-variants, .page-product:not(.modal-open) .row .product-variants").replaceWith(data.product_variants)
  $(".product-add-to-cart").replaceWith(data.product_add_to_cart)
}

function initSortSelect () {
  const $sortSelect = $('.select2__caller')
  let sortSelect = null

  if ($sortSelect.length) {
    sortSelect = new Select($sortSelect, {
      minimumResultsForSearch: -1
    })
    sortSelect.init()
  }
}

function toggleProductImageView () {
  $('#productHover').hide()
  $('#productCarouselIndicators').show()
}

function triggerDeliveryShops () {
  const pickupStoreId = $('.delivery__shops').data('show-on-id')
  const $pickupStoreOptions = $('#pickupStoreOptions')
  let currentDelivery = Array.from(document.querySelectorAll('.js-delivery-option')).find(item => item.checked)

  currentDelivery =  currentDelivery.value
  currentDelivery = currentDelivery.substring(0,currentDelivery.indexOf(','))

  if (currentDelivery == pickupStoreId) {
    $pickupStoreOptions.show()
  } else {
    $pickupStoreOptions.hide()
  }
}