export default class Map {
  constructor($map, center, zoom) {
    this.$map = $map
    this.map = null
    this.center = center
    this.zoom = zoom
    this.markers = JSON.parse($map.dataset.stores)
  }

  init() {
    this.map = new google.maps.Map(this.$map, {
      center: this.center,
      zoom: this.zoom
    })

    if (this.markers && Array.isArray(this.markers)) {
      this.setMarkers()
    }
  }

  setMarkers () {
    this.markers.forEach(marker => {
      new google.maps.Marker({
        position: {
          lat: parseFloat(marker.latitude),
          lng: parseFloat(marker.longitude)
        },
        map: this.map
      })
    })
  }

  panToMarker (marker) {
    this.map.panTo({
      lat: parseFloat(marker.latitude),
      lng: parseFloat(marker.longitude)
    })
  }
}